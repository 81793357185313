import Q from 'q';
import _ from 'underscore';

import TravelMultipleCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/travel-city-multiple-selector/1.0.0/index.vue';

export default {
    components: {
        TravelMultipleCitySelector,
    },
    data() {
        return {
            selected_id_list: null,

            //在这里赋值不能正常初始化，暂时解决不了
            // selected_id_list: [
            //     442000,//中山
            //     440100,//广州
            // ],
        };
    },
    methods: {
        __btn_get_value_click() {
            const __this = this;
            console.log(__this.selected_id_list);
        },

        __btn_set_value_click() {
            const __this = this;
            __this.selected_id_list = [
                442000,//中山
                440100,//广州
            ];
        },

        __btn_reset_value_click() {
            const __this = this;
            __this.selected_id_list = [];
        },

        __btn_get_selected_entity_list_click() {
            const __this = this;
            const selected_entity_list = __this.$refs.aTravelCitySelector.get_selected_entity_list();
            console.log(selected_entity_list);
        },
    },
    watch: {
        selected_id_list(pNewValue, pOldValue) {
            const __this = this;
            // console.log(`pNewValue=`);
            // console.log(pNewValue);
            // console.log(`pOldValue=`);
            // console.log(pOldValue);
            const selected_entity_list = __this.$refs.aTravelCitySelector.get_selected_entity_list();
            console.log(selected_entity_list);
        },
    },
    created() {
        const __this = this;
        //在这里赋值不能正常初始化，暂时解决不了
        // __this.selected_id_list = [
        //     442000,//中山
        //     440100,//广州
        // ];
    },
    mounted() {
        const __this = this;
        // __this.selected_id_list = [
        //     442000,//中山
        //     440100,//广州
        // ];
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
